import React from 'react'
import Signup from './Form/Patient/Signup'
import {
  Container,
  SubTitle,
  Info,
  FormContainer,
  LoginBtn,
  TitleContainer,
  TabPanel,
  LogoImage,
} from './Registration.styles'
import { useState } from 'react'
import FormTabs from './RegistrationTabs'
import ProviderSignup from './Form/Provider/ProviderSignup'
import SupplementalServiceProviderSignup from './Form/Provider/SupplementalServiceProviderSignup'

const Registration = ({ user, loginWithRedirect }) => {
  const [activeTab, setActiveTab] = useState('Patient')

  return (
    <Container>
      <Info>
        <TitleContainer>
          <SubTitle>
        	<LogoImage />
            <br />
            Become the best version of you! Already
            have an account? &nbsp; &nbsp;
            <span>
              <LoginBtn onClick={() => loginWithRedirect()}>Login here</LoginBtn>
            </span>
            <br />
          </SubTitle>
        </TitleContainer>
      </Info>
      <FormContainer>
        <FormTabs activeTab={activeTab} setActiveTab={setActiveTab} />
        <TabPanel activeTab={activeTab} name={'Patients'}>
          <Signup user={user} loginWithRedirect={loginWithRedirect} />
        </TabPanel>
        <TabPanel activeTab={activeTab} name={'Doctors and Care Providers'}>
          <ProviderSignup user={user} loginWithRedirect={loginWithRedirect} />
        </TabPanel>
        <TabPanel activeTab={activeTab} name={'Others/SSPs'}>
          <SupplementalServiceProviderSignup user={user} loginWithRedirect={loginWithRedirect} />
        </TabPanel>
      </FormContainer>
    </Container>
  )
}


export default Registration
