import { instance, API_PATHS } from '.'
import { globalConfig } from '../config'

export const getFipsCountries = () => {
  let URL = `${globalConfig.apiPublicURL}/fips/countries`
  return instance.get(URL)
}

export const getFipsRegions = (fipsCountryCode) => {
  let URL = `${globalConfig.apiPublicURL}/fips/regions/${fipsCountryCode}`
  return instance.get(URL)
}

