import React, { useRef, useEffect, useState } from 'react'
//import { React, useEffect } from 'react'
import { MenuItem, TextField, Typography } from '@mui/material'
import { InputAdornment } from '@material-ui/core'
import PersonIcon from '@mui/icons-material/Person'
import HomeIcon from '@mui/icons-material/Home'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import Password from '@mui/icons-material/Password'
import Email from '@mui/icons-material/Email'
import MarkunreadMailboxIcon from '@mui/icons-material/MarkunreadMailbox'
import LanguageIcon from '@mui/icons-material/Language'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

import { useFormikContext } from 'formik'
import { InputWrapper } from '../Form.styles'
import { useQuery } from 'react-query'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import Button from '@mui/material/Button'
import { fips_countries, fips_regions, socialLinks } from '../Form-data/index'
import { getFipsCountries } from '../../../../services/gazelle-api'
import { getFipsRegions } from '../../../../services/gazelle-api'

const theme = createTheme({
  palette: {
    euphoria: {
      main: '#25be77',
      contrastText: '#fff',
    },
  },
})
//const themeBold = createTheme({
//	typography: {
//		fontWeightBold: 700,
//	}
//})
const UserDetails = ({ nextStep }) => {

const [selectedCountry, setSelectedCountry] = useState('')

  const {
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    validateForm,
    validateField,
    setTouched,
    values,
    touched,
    errors,
  } = useFormikContext()

  const handleSubmitUserDetails = async () => {
    setTouched({
      firstName: true,
      middleName: true,
      lastName: true,
      mobilePhone: true,
      address: true,
      address2: true,
      city: true,
      state: true,
      country: true,
      postalCode: true,
      dateOfBirth: true,
    })

    const data = await validateForm()

    const isValid = Object.keys(data).length === 0
    if (isValid) {
      return handleSubmit();
    }
  }
  
  const getCountriesQuery = useQuery(`countries:`, () => getFipsCountries(), {
    staleTime: 60000
  })

  const getRegionsQuery = useQuery(`regions:${selectedCountry}`, () => getFipsRegions(selectedCountry), {
    staleTime: 60000
  })

  return (
    <>
      <ThemeProvider theme={theme}>
        <Typography variant="h5" component="div" gutterBottom>
          Personal details
        </Typography>
        {/* // Password, Username */}
        <InputWrapper>
          <TextField
            id="outlined-basic"
            className="Input-div"
            label={
              errors.firstName && touched.firstName
                ? errors.firstName
                : 'first name'
            }
            variant="outlined"
            margin="normal"
            value={values.firstName}
            onChange={handleChange('firstName')}
            error={errors?.firstName && touched.firstName ? true : false}
            onBlur={() => setFieldTouched('firstName')}
            fullWidth
            placeholder="first name"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonIcon />
                </InputAdornment>
              ),
            }}
          />
          {/*
          <TextField
            id="outlined-basic"
            className="Input-div"
            label={
              errors.middleName && touched.middleName
                ? errors.middleName
                : 'middle name'
            }
            variant="outlined"
            margin="normal"
            value={values.middleName}
            onChange={handleChange('middleName')}
            error={errors?.middleName && touched.middleName ? true : false}
            onBlur={() => setFieldTouched('middleName')}
            fullWidth
            placeholder="middle name"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonIcon />
                </InputAdornment>
              ),
            }}
          />*/}
        </InputWrapper>
        <InputWrapper>
          <TextField
            id="outlined-basic"
            className="Input-div"
            label={
              errors.lastName && touched.lastName
                ? errors.lastName
                : 'last name'
            }
            variant="outlined"
            margin="normal"
            value={values.lastName}
            onChange={handleChange('lastName')}
            fullWidth
            error={errors?.lastName && touched.lastName ? true : false}
            onBlur={() => setFieldTouched('lastName')}
            placeholder="last name"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonIcon />
                </InputAdornment>
              ),
            }}
          />
        </InputWrapper>
        <InputWrapper>
          <TextField
            id="outlined-basic"
            className="Input-div"
            label={
              errors.mobilePhone && touched.mobilePhone
                ? errors.mobilePhone
                : 'phone number'
            }
            variant="outlined"
            margin="normal"
            value={values.mobilePhone}
            onChange={handleChange('mobilePhone')}
            error={errors?.mobilePhone && touched.mobilePhone ? true : false}
            onBlur={() => setFieldTouched('mobilePhone')}
            fullWidth
            type="tel"
            required
            placeholder="phone number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocalPhoneIcon />
                </InputAdornment>
              ),
            }}
          />
        </InputWrapper>




        <InputWrapper>
          <TextField
            id="outlined-basic"
            className="Input-div"
            label={
              errors.address && touched.address
                ? errors.address
                : 'address'
            }
            variant="outlined"
            margin="normal"
            value={values.address}
            onChange={handleChange('address')}
            error={errors?.address && touched.address ? true : false}
            onBlur={() => setFieldTouched('address')}
            fullWidth
            placeholder="address"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <HomeIcon />
                </InputAdornment>
              ),
            }}
          />
        </InputWrapper>
        <InputWrapper>

          <TextField
            id="outlined-basic"
            className="Input-div"
            label={
              errors.address2 && touched.address2
                ? errors.address2
                : 'address2'
            }
            variant="outlined"
            margin="normal"
            value={values.address2}
            onChange={handleChange('address2')}
            error={errors?.address2 && touched.address2 ? true : false}
            onBlur={() => setFieldTouched('address2')}
            placeholder="apt/unit"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <HomeIcon />
                </InputAdornment>
              ),
            }}
          />
        </InputWrapper>

        <InputWrapper>

          <TextField
            id="outlined-basic"
            className="Input-div"
            label={
              errors.city && touched.city
                ? errors.city
                : 'city'
            }
            variant="outlined"
            margin="normal"
            value={values.city}
            onChange={handleChange('city')}
            error={errors?.city && touched.city ? true : false}
            onBlur={() => setFieldTouched('city')}
            placeholder="city"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <HomeIcon />
                </InputAdornment>
              ),
            }}
          />
        </InputWrapper>

        <InputWrapper>
          <TextField
            id="outlined-basic"
            className="Input-div"
            select
            label={
              errors.country && touched.country
                ? errors.country
                : 'Select your country'
            }
            helperText={'Select your country'}
            variant="outlined"
            margin="normal"
            value={values.country}
            onChange={ (e, v) => {
            	const fn = handleChange('country') 
            	fn(e, v) 
            	setSelectedCountry(v.props.countrycode)            	
            	} 
            }
            error={errors?.country && touched.country ? true : false}
            onBlur={() => setFieldTouched('country')}
            placeholder="country"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocationOnIcon />
                </InputAdornment>
              ),
            }}
          >
            {getCountriesQuery?.data?.data?.map(el => {
              return (
                <MenuItem countrycode={el.COUNTRY_CODE} key={el.COUNTRY_CODE} value={parseInt(el.FIPS_COUNTRY_CODE)}>
                  {el.COUNTRY_NAME}
                </MenuItem>
              )
            })}
          </TextField>
        </InputWrapper>

        <InputWrapper>
          <TextField
            id="outlined-basic"
            className="Input-div"
            select
            label={
              errors.state && touched.state ? errors.state : 'Select your state/region'
            }
            helperText={'Select your state/region'}
            variant="outlined"
            margin="normal"
            value={values.state}
            onChange={handleChange('state')}
            error={errors?.state && touched.state ? true : false}
            onBlur={() => setFieldTouched('state')}
            fullWidth
            placeholder="state"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocationOnIcon />
                </InputAdornment>
              ),
            }}
          >
            {getRegionsQuery?.data?.data?.map(el => {
              return (
                <MenuItem key={el.FIPS_REGION_CODE} value={parseInt(el.FIPS_REGION_CODE)}>
                  {el.REGION_NAME}
                </MenuItem>
              )
            })}
          </TextField>
        </InputWrapper>

        <InputWrapper>
          <TextField
            id="outlined-basic"
            className="Input-div"
            label={
              errors.country && touched.postalCode
                ? errors.postalCode
                : 'postal code'
            }
            variant="outlined"
            margin="normal"
            value={values.postalCode}
            onChange={handleChange('postalCode')}
            error={errors?.postalCode && touched.postalCode ? true : false}
            onBlur={() => setFieldTouched('postalCode')}
            placeholder="postal code"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MarkunreadMailboxIcon />
                </InputAdornment>
              ),
            }}
          />
        </InputWrapper>

        <InputWrapper>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              disableFuture
              fullWidth
              id="date-picker-dialog"
              className="Input-div"
              openTo="year"
              views={['year', 'month', 'date']}
              label={
                errors.dateOfBirth && touched.dateOfBirth
                  ? errors.dateOfBirth
                  : 'date of birth'
              }
              helperText={null}
              inputVariant="outlined"
              format="dd/MM/yyyy"
              margin="normal"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CalendarTodayIcon />
                  </InputAdornment>
                ),
              }}
              onBlur={() => setFieldTouched('dateOfBirth')}
              error={errors?.dateOfBirth && touched.dateOfBirth ? true : false}
              onChange={date => {
                setFieldTouched('dateOfBirth')
                setFieldValue('dateOfBirth', date)
              }}
              value={values.dateOfBirth}
            />
          </MuiPickersUtilsProvider>
        </InputWrapper>
        <InputWrapper>
          <TextField
            select
            fullWidth
            id="outlined-select"
            className="Input-div"
            label="How did you hear about us?"
            placeholder='="How did you hear about us?'
            value={values.socialConnection}
            onChange={handleChange('socialConnection')}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LanguageIcon />
                </InputAdornment>
              ),
            }}
          >
            {socialLinks.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.value}
              </MenuItem>
            ))}
          </TextField>
        </InputWrapper>

        <InputWrapper>
          <TextField
            id="outlined-basic"
            className="Input-div"
            label={
              errors.email && touched.email
                ? errors.email
                : 'email'
            }
            variant="outlined"
            margin="normal"
            value={values.email}
            onChange={handleChange('email')}
            fullWidth
            error={errors?.email && touched.email ? true : false}
            onBlur={() => setFieldTouched('email')}
            placeholder="email"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Email />
                </InputAdornment>
              ),
            }}
          />
        </InputWrapper>
        <InputWrapper>
          <TextField
            id="outlined-basic"
            className="Input-div"
            label={
              errors.password && touched.password
                ? errors.password
                : 'password'
            }
            variant="outlined"
            margin="normal"
            value={values.password}
            onChange={handleChange('password')}
            error={errors?.password && touched.password ? true : false}
            onBlur={() => setFieldTouched('password')}
            fullWidth
            type="password"
            required
            placeholder="password"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Password />
                </InputAdornment>
              ),
            }}
          />
        </InputWrapper>
        <div style={{ alignSelf: 'flex-end' }}>
          <Button
            color="euphoria"
            size="large"
            variant="contained"
            onClick={async () => {
              handleSubmitUserDetails()
            }}
          >
            Sign Up
          </Button>
        </div>
      </ThemeProvider>
    </>
  )
}

export default UserDetails
